@import '../scss/variables';
@import '../scss/functions';

.select_global {
  position: relative;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    border: 0.5px solid #AAAAAA;
    border-radius: 4px;
    padding: 10px !important;
    color: #4E4E4E;
    cursor: pointer;
  }

  .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    fill: #4E4E4E;
    pointer-events: none;
  }
}


.toasts-wrapper {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 11111;
  max-width: 500px;
  width: 100%;
}

.toast {
  border: 2px solid transparent;
  background-color: #fafafa;
  border-radius: 4px;
  max-width: 480px;
  box-shadow: 0 0 5px rgba(0, 0, 0, .2);
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  animation: toast-in-right .3s;
  color: #fff;
  padding: 8px;
}

.toast__text {
  color: black;
}

.toast__close-btn {
  background-color: transparent;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #ebebeb;
  padding: 3px;
  border-radius: 5px;

  &:hover {
    background-color: #ebebeb;
    color: #646363;
  }
}

.custom_textArea_checkout {
  flex: 1;
  height: 100px;
  position: relative;

  &:hover {
    border-color: black;
  }

  & textarea {
    border: 1px solid #cdcdcd;
    width: 100%;
    height: 100%;
    padding: 10px;
    outline: none;
    transition: .1s ease-in;
    border-radius: 5px;
    resize: none;
  }

  & label {
    position: absolute;
    top: 20px;
    transform: translateY(-50%);
    left: 12px;
    padding: 0 5px;
    transition: .1s ease-in;
    pointer-events: none;
  }
}

.custom_textarea:focus ~ .custom_input_label,
.custom_textarea:not(:placeholder-shown).custom_textarea:not(:focus) ~ .custom_input_label {
  top: 0;
  font-size: 13px;
  color: #1e72bd;
  background-color: white;
}

.custom_textarea:focus {
  border-color: #1e72bd;
}

.custom_input_field:focus ~ .custom_input_label,
.custom_input_field:not(:placeholder-shown).custom_input_field:not(:focus) ~ .custom_input_label {
  top: 0;
  font-size: 13px;
  color: #1e72bd;
  background-color: white;
}

.custom_input_field:focus {
  border-color: #1e72bd;
}

.red-fms {
  color: red;
}

.custom_input_checkout {
  flex: 1;
  height: 45px;
  position: relative;

  &:hover {
    border-color: black;
  }

  & input {
    border: 1px solid #cdcdcd;
    width: 100%;
    height: 100%;
    padding: 10px;
    outline: none;
    transition: .1s ease-in;
    border-radius: 5px;
  }

  & label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
    padding: 0 5px;
    transition: .1s ease-in;
    pointer-events: none;
  }
}

.err_field {
  border: 1px solid red !important;
}

.signUp-position-relative {
  > div {
    width: 100%;
    margin-top: 15px;
  }
}

.input-paddings {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.signUp-position-radio-flex {
  align-items: center;
  justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  grid-gap: 10px;

  label {
    margin-left: 7px;
  }
}

.signUp-position-no-input {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  margin-left: 15px
}

.custom_multiselect {
  width: 100%;
  border: 1px solid #707070;
  position: relative;
  border-radius: 2px;

  &.open {
    .custom_select-button {
      .custom_multiselect-arrow {
        transform: rotate(180deg);
      }
    }

    .custom_multiselect-drop_down {
      visibility: visible;
      opacity: 1;
    }
  }

  .custom_multiselect-button {
    height: 100%;
    width: 100%;
    min-height: 48px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    padding: 5px;
    gap: 7px;

    .custom_multiselect-button_selected {
      padding: 7px 7px 7px 9px;
      text-transform: capitalize;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      display: flex;
      gap: 14px;

      background: #C5C5C5;
      border-radius: 2px;

      .selected_del_btn {
        background: none;
        border: none;

        svg {
          pointer-events: none;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .custom_multiselect-arrow {
      position: absolute;
      right: 12px;
      top: 12px;
      transition: .5s;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .custom_multiselect-drop_down {
    border: 1px solid #707070;
    border-radius: 2px;
    position: absolute;
    width: calc(100% + 2px);
    left: -1px;
    top: 100%;
    z-index: 4;
    background: white;
    visibility: hidden;
    opacity: 0;


    .custom_multiselect-option {
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 12px;

      &.selected_option,
      &:hover {
        cursor: pointer;
        background: #C5C5C5;
      }
    }
  }
}

.signUp-position-yes-no {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  label {
    margin-left: 7px;
  }
}

.signUp-position-yes-input {
  height: 20px;
  width: 20px;
  vertical-align: middle
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@media screen and (max-width: 600px) {
  .toasts-wrapper {
    left: auto;
    right: 0;
    top: 1em;
    max-width: 350px;
    width: 100%;
  }

  .toast__text {
    font-size: 13px;
  }
}

