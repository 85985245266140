/*
// .footer-contacts
*/
@import "../../../scss/variables";
@import "../../../scss/functions";
@import "../../../scss/mixins/direction";

.footer-contacts {
  display: flex;
  justify-content: center;
}




.footer-contacts__title {
  margin-bottom: 25px;
}

.footer-contacts__text {
  font-size: 15px;
  line-height: 22px;
  list-style: none;
  margin: 0;
  padding: 0;

  @include direction {
    #{$padding-inline-end}: 20px;
  }

  a {
    color: inherit;
  }
}

.footer-contacts__contacts {
  font-size: 16px;
  list-style: none;
  padding: 0;
  text-align: left;
  color: #303030;

  div {
    cursor: pointer;
  }

  a {
    color: inherit;
  }

  li {
    position: relative;
  }

  .contactsAddress {
    padding: 0;
    line-height: 19px;
    //margin-bottom: 10px;
    margin-bottom: 16px;
    display: block;

    a {
      cursor: pointer;
    }
  }

}

.footer-contacts__icon {
  display: inline-block;
  text-align: center;
  width: 22px;

  @include direction {
    #{$margin-inline-end}: 2px;
  }
}

@media (max-width: breakpoint(sm-end)) {
  .footer-contacts {
    justify-content: flex-start;
    text-align: center;
    //margin-top: 30px;
  }
  .footer-contacts__title {
    font-size: 28px;
    margin-bottom: 16px;
  }
  .footer-contacts__text {
    padding: 0;
  }
}
//


.mobile-footer__body {
  display: flex;
  align-items: center;
  height: $nav-panel-height;
  border-top: 1px solid silver;
}

.mobile-footer__indicators {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.mobile-footer__panel .container {
  max-width: unset;
  margin-right: unset;
  margin-left: unset;
}

.mobile-footer__indicators > div {
  width: 24.5%;
  display: flex;
  justify-content: center;
  height: 60px;
  align-items: center;
}

.contact-with-input{
  height: 45px;
  border-radius: 9px;
}



.required {
  .MuiInputBase-formControl {
    border-radius: 9px;
  }

  margin-bottom: 40px;
}
////////////////////////////////////////
.footer-links__title {
  margin-bottom: 22px;
}
.footer-links__list {
  font-size: 15px;
  line-height: 28px;
  list-style: none;
  padding: 0;
  margin: 0;

  a {
    color: inherit;
    transition: .15s;
  }
  a:hover {
    color: #359f45;
  }
}


@media (max-width: breakpoint(sm-end)) {
  .footer-links__title {
    margin-bottom: 12px;
  }
  .footer-links {
    margin-top: 42px;
    text-align: center;
  }
}

//////////////////////////////

.footer-newsletter__title {
  margin-bottom: 16px;
  color: #121212;
  font-size: 18px;
}
.footer-newsletter__text {
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 14px;
}
.footer-newsletter__text--social {
  margin-top: 20px;
  margin-bottom: 0;
}

.footer-newsletter__social-links {
  display: flex;
}
.footer-newsletter__form {
  display: flex;
  // max-width: 320px;
  flex-direction: column;
}
.footer-newsletter__form-input {
  min-width: 0;
  padding: 20px;
  font-size: 15px;
  border-radius: 9px;
  &::placeholder {
    font-size: 14px;
  }
}


@media (max-width: breakpoint(md-end)) {
  .footer-newsletter {
    margin-top: 42px;
    max-width: 420px;
  }
  .footer-newsletter__title {
    // text-align: center;
    margin-bottom: 16px;
  }
}
@media (max-width: breakpoint(sm-end)) {
  .footer-newsletter {
    // text-align: center;

    @include direction {
      #{$margin-inline-start}: auto;
      #{$margin-inline-end}: auto;
    }
  }
  .footer-newsletter__title {
    font-size: 18px;
  }
  // .footer-newsletter__form {
  //   margin: 0 auto;
  // }
  .footer-newsletter__social-links {
    margin-top: 16px;
    justify-content: center;
  }
}

@media (max-width: breakpoint(xs-end)) {
  .mobile-footer-newsletter {
    width: 100%;
  }
  .mobile-footer-dropdowns-list {
    width: 100%;
  }
  .mobile-footer-dropdown {
    border-bottom: 1px solid silver;
    width: 100%;
  }
  .footer-title span {
    margin-left: auto;
  }
  //.footer-title {
  //  margin-top: 10px;
  //}
}

////////////////////////////////////////
.site-footer {
  padding: 34px 0;
  min-height: 225px;
  background-color: #FFF;
}

@media screen and (max-width: 768px) {
  .site-footer {
    height: max-content;
  }
  .footer-title{
  width: 100%;
  }
}

//////////////////////////////
$local-size: 39px;
$local-bottom: 36px;
$local-margin-start: 80px;
$local-margin-end: 24px;

.toTop {
  position: relative;
  z-index: 9;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0s 0.3s;
}
.toTop__body {
  position: fixed;
  bottom: $local-bottom;
  right: calc((100% - (1260px + 70px)) / 2);
  top: auto;
  display: flex;
  margin-top: -(ceil($local-size / 2));
}
.toTop__container {
  margin: 0 15px;
}
.toTop__end {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;

  &:before {
    content: "";
    flex-grow: 1;
  }
}
.toTop__button {
  pointer-events: auto;
  width: $local-size;
  height: $local-size;
  border-radius: $local-size / 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  cursor: pointer;
  color: $btn-primary-font-color;
  background: $btn-primary-bg-color;
  fill: currentColor;
  flex-shrink: 0;
  transition: background-color 0.15s, color 0.15s;

  @include direction {
    #{$margin-inline-start}: $local-margin-start;
    #{$margin-inline-end}: $local-margin-end;
  }

  &:focus {
    outline: none;
  }

  @media (hover: hover) {
    &:hover {
      color: $btn-primary-hover-font-color;
      background: $btn-primary-hover-bg-color;
    }
  }

  &:active {
    color: $btn-primary-active-font-color;
    background: $btn-primary-active-bg-color;
  }

  svg {
    margin-top: -2px;
    margin-bottom: 1px;
  }
}
.toTop--show {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s, 0s;
}
////////////////////////////
// from custom.scss

.footer-body {
  display: flex;
  justify-content: space-between;
  min-height: 134px;
  width: 100%;
  margin-bottom: 40px;
}

.footer-newsletter-hide {
  display: block;
}

.footer-container {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1260px;
  width: 100%;

  .footer-body {
    .footer-first-column,
    .footer-newsletter-hide {
      max-width: calc((100% - 300px) / 2);
    }

    .newsletter-block {
      max-width: 300px;

      .footer-newsletter-container {
        .footer-newsletter__form {
          .custom_input_checkout {
            width: 300px;
          }
        }
      }
    }
  }

  .copyright {
    font-size: 15px;
    color: #2c2c2c;
    border-top: 1px solid #707070;
  }
}

.footer_text {
  font-size: 16px;
  font-weight: 300;
  max-width: 200px;
  margin: 10px 0;
  color: #303030;
}

.mobile-footer {
  display: none;
  width: 100%;
  height: max-content;
  margin-top: 16px;
  background: $footer-bg;
  border-top: $footer-border-top;
  padding: 30px 0 50px;

  .copyright {
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #2c2c2c;
  }
}

.site-footer__widget > div {
  justify-content: flex-start !important;
}
.footerLinks-fms a,
.trackingNumber-fms a {
  color: #707070 ;
  font-size: 16px;
}

@media screen and (max-width: 1200px) {
  .footer-container {
    max-width: 960px;
  }
}

@media screen and (max-width: 992px) {
  .footer-container {
    max-width: 720px;
  }
}

.newsletter-block {
  display: block;
  width: 300px;
}

.newsletter-block-mobile {
  display: none;
}

.footer-contacts-mobile {
  display: none;
}

.footer-contacts-body {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.footer-newsletter__form-button {
  max-width: 100%;
  width: 100%;
  border-radius: 8px;
  margin-top: 15px;
  color: #fff;
  background: $btn-primary-bg-color;
  font-size: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border: none;

  &:hover {
    background-color: $btn-primary-hover-bg-color;
    transition: all 0.1s ease-in-out;
    color: white;
  }

  &:focus {
    color: white;
  }
}


@media screen and (max-width: 991px) {
  .mobile-footer {
    .container {
      padding-right: 10px;
      padding-left: 10px;
    }

    display: block !important;
    background-color: #faf7f7 !important;
  }
  .footer-newsletter__form-button {
    font-size: 16px;
    //padding: 16.5px 14px;
    max-width: 100% !important;
  }
  .mobile-footer-newsletter {
    width: 100%;
    padding: 0 50px 20px;
  }
  .mobile-footer-arrow {
    //.d-block {
    path {
      fill: #999 !important;
    }

    //}
  }
  .footer-contacts {
    flex-direction: column;
  }
  .footer-contacts__contacts,
  .mobile-footer-content {
    //max-width: 184px;
    margin: 10px 0;
  }
}


@media screen and (max-width: 768px) {
  .footer-container {
    max-width: 700px;
  }

  .footer-body {
    display: flex;
    flex-direction: column;
  }

  .footer-contacts-body {
    margin-top: 10px;
    max-width: 420px;
    width: 100%;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
  }

  .newsletter-block {
    display: none;
  }

  .footer-newsletter__form-button {
    max-width: 100%;
  }

  .footer-newsletter__form {
    width: 100%;
  }

  .footer-newsletter-hide {
    display: none;
  }

  .footer-contacts-mobile {
    display: block;
  }

  .footer-newsletter__social-links {
    justify-content: flex-start;
  }
}
@media screen and (min-width: 576px) {

  .footer-title {
    margin-top: 10px;
  }
}

@media screen and (min-width: 600px) {
  .footer-newsletter__form-button {
    max-width: unset;
  }

  .footer-title span {
    margin-left: auto;
  }

  //.footer-title {
  //  margin-top: 10px;
  //}

  .mobile-footer-dropdown {
    width: 100%;
    border-bottom: 1px solid silver;
  }


  .already-have-account-fms {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }

  .already-have-account-responsive {
    text-align: inherit;
    margin: 0;
  }
}

@media screen and (max-width: 575px) {
  .mobile-footer {
    margin-bottom: 50px;
    .container {
      padding: 0;
    }
  }
}

@media screen and (max-width: 568px) {

  .newsletter-block-mobile {
    display: block;
  }
}




@media screen and (max-width: 440px) {
  .footer-body {
    padding: 20px;
  }

  .footer-link-style {
    font-size: 11px;
  }

  .footer-contacts__contacts {
    font-size: 14px;
    margin-bottom: 30px;
  }


}

.footer-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
}
.footer-title span {
  padding-left: 10px;
}

.mobile-footer-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 15px;
}

@media (max-width: breakpoint(xs-end)) {
  .mobile-footer-container {
    //flex-direction: column-reverse;
    align-items: flex-start;
  }

  .footer-newsletter__form-button {
    margin-bottom: 30px;
  }
}

@media (max-width: breakpoint(xs-end)) {
  .mobile-footer-container {
    //flex-direction: column-reverse;
    align-items: center;
  }
  .footer-newsletter__form-button {
    margin-bottom: 15px;
  }
  .site__footer {
    margin-top: 20px;
  }
}


.track-body {
  max-width: 500px;
  min-width: 250px;
  width: 100%;
  min-height: 313px;
  border: 2px solid #dcdcdc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //margin-left: auto;
  //margin-top: 9%;
  margin: 9% auto;
  //margin-right: auto;
  padding: 40px 10px;
  border-radius: 10px;

  h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 40px;
  }
}

@media(max-width: 380px) {
  .track-body {
    h1 {
      font-size: 24px;
    }
  }

}

.tracking-number-btn {
  width: 100%;
}

.track-body-input {

  max-width: 340px;
  min-width: 253px;
  width: 100%
  // form {
  //   width: 100%;
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: center;
  // }
}

.tracking-number-input {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  // box-shadow: 5px 5px 5px #dcdcdc;
  // font-size: 28px;
  // padding-left: 10px;
  min-height: 45px;

  .custom_input_label {
    top: 42%;
  }
}

@media screen and (min-width: 576px) and (max-width: 600px) {
  .mobile-footer-arrow {
    margin-left: auto;
  }
}


.track-body-input button {
  width: 100%;
  height: 42px;
  border-radius: 10px;
  //background-color: $btn-primary-bg-color;
  background: $btn-primary-bg-color;
  color: white;
  border: 0.5px solid $btn-primary-bg-color;
  // margin-left: 50px;
  box-shadow: 5px 5px 5px #dcdcdc;
  margin-top: 10px;
  cursor: pointer;
  /* Track */
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.track-body-input button:hover {
  //background-color: $btn-primary-hover-bg-color;
  background: $btn-primary-hover-bg-color;
}
@media screen and (max-width: 1250px) {
  .toTop__button {
    margin-right: 20px;
  }
}
@media screen and (max-width: 479px) {
  .mobile-footer-newsletter {
    padding: 0 16px 20px;
  }
}

///////////////////////////////
// mobile menu footer

.mobileMenuFooter-fms {
  padding: 0 10px;
  background: rgba(13, 71, 161, 0.05);

  .mobile-footer-dropdown {
    .mobile-footer-dropdown-divs {
      .topBar-dropdown--opened {
        min-height: auto !important;
      }

      //.lang-mobile-symbol{
      //  margin-left: 10px;
      //}

      .topBar-dropdown__btn {
        fill: #999;

        .lang-name-image {
          .lang-name {
            margin: 0;
            font-size: 16px;
            font-family: "Open Sans", sans-serif;

          }

          .currency {
            margin-left: 22px;
          }

          img {
            margin: auto 10px auto 0;
            //margin-right: 10px;
          }

          .lang-mobile-img {
            margin-left: 10px;
          }


        }


      }
    }
  }

  .menu {
    width: unset;
    box-shadow: unset !important;
    background: unset;

    //li {
    //  border-bottom: 1px solid white;
    //}

    .menu__icon {
      position: unset;
      padding-right: 10px;
    }

  }

  .topBar-dropdown__body {
    right: unset;
    transition: unset !important;

    div {
      // height: 54px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 0 !important;
    }
  }

  .topBar-dropdown__btn {
    padding-left: unset !important;

    span {
      display: block;
    }

    .mobileversion-lang {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      padding-left: 3px;

      span {
        margin-left: 10px;
        padding: 0 !important;
      }
    }
  }

  .null-icon-fms {
    margin-left: unset;
  }

  .topBar-dropdown__btn {
    svg {
      margin-right: 10px;
      transition: transform 0.3s;
    }
  }

  .footer-newsletter__social-links {
    margin-top: unset;
  }

  .mobile-footer-dropdown-divs {
    min-height: 60px;
    border-bottom: 1px solid white;
    font-size: 15px;
    color: #3d464d;
    display: flex;
    align-items: center;

    .lang-name-image {
      flex-direction: row-reverse;

    }

    .footer-contacts {
      .footer-title-opened {
        margin-top: 19px;
      }
    }
  }

  .mobile-footer-dropdown-last-divs {
    min-height: 60px;
    display: flex;
    align-items: center;
  }

  .topBar-dropdown,
  .site-footer__widget,
  .site-footer__widget {
    width: 100%;
  }

  .topBar-dropdown,
  .footer-newsletter__social-links {
    padding: 0 10px;
  }

  .footer-title {

    padding: 0 10px;
    margin-right: 10px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;

    .contacts-path-fms {
      margin-right: 10px;

      path {
        fill: #3d464d !important;
      }
    }

    .mobile-footer-arrow {
      transition: transform 0.3s;
    }
  }

  .footer-contacts__contacts {
    padding: 0 40px;

    p, a {
      font-size: 16px;
      font-family: "Open Sans", sans-serif;
      width: 100%;
      word-wrap: break-word;
    }
  }

  .topBar-dropdown--opened {
    .topBar-dropdown__btn {
      margin-top: 22px;
    }

    .topBar-dropdown__body {
      padding-top: unset;
    }

    .menu {
      margin-bottom: 0;
    }
  }

  .social-links__item {
    margin-right: 15px !important;
  }
}

.mobile-footer-dropdowns-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

////////////////////////////////////////////////////////////
// from site footer


.site-footer__widget {
  & + & {
    margin-top: 24px;
  }
}

@media (max-width: breakpoint(sm-end)) {

  .site-footer__bottom {
    padding: 20px 0 24px;
    flex-wrap: wrap;
    height: auto;
  }

  .site-footer__payments {
    order: -1;
  }

  .site-footer__copyright + .site-footer__payments {
    margin-bottom: 24px;
  }

  .site-footer__copyright,
  .site-footer__payments {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .footer-newsletter-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    //margin-top: 30px;
  }
}